html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.link {
  color: #5f27cb;
}
a.link:hover {
  color: #a027cb;
  text-decoration: underline;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.one-line-ellipsis-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1; /* Limit to 1 line */
}

/* Datepicker */
.react-datetime-picker__wrapper {
  height: 25px;
  background: #e4ecfc;
  border: 1px solid #cbd8f1;
  border-radius: 40px;
}
.react-datetime-picker {
  width: 100%;
}
.react-datetime-picker__wrapper {
  align-items: center;
  border: none !important;
}
.react-datetime-picker__button.react-datetime-picker__clear-button,
.react-datetime-picker__button.react-datetime-picker__calendar-button {
  padding: 0;
  width: 25px;
  display: flex;
  justify-content: center;
}
.react-datetime-picker__inputGroup {
  font-size: 10px;
  line-height: 14px;
  color: #666666;
  padding: 0 10px !important;
}
.react-datetime-picker__inputGroup__input {
  width: 12px !important;
  padding-left: 0 !important;
}
.react-datetime-picker__inputGroup__year {
  width: 24px !important;
}
.react-datetime-picker__inputGroup__amPm {
  width: 36px !important;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  /*color: transparent;*/
  /*background: none;*/
  color: #e2e8f0;
  height: 15px;
  width: 15px;
  z-index: 1;
  margin-right: -6px;
}
